import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Alert, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const InstallMetamaskSnackbar = ({ open, onClose }) => {
    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open} onClose={onClose}>
            <Alert
                onClose={onClose}
                severity="warning"
                sx={{ width: '100%' }}
                action={
                    <>
                        <Button
                            variant="text"
                            color="inherit"
                            href="https://metamask.io/download/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ fontSize: '0.875rem', px: 1, py: 0.5, borderRadius: '4px' }}
                        >
                            前往下載
                        </Button>
                        <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            >
                請下載及安裝 MetaMask
            </Alert>
        </Snackbar>
    )
}

InstallMetamaskSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default InstallMetamaskSnackbar
