import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { TableRow, TableCell, Box, Typography } from '@mui/material'

const HiddenRow = () => {
    return (
        <>
            <TableRow id="expand-hidden-row" key={uuidv4()} sx={{ backgroundColor: 'background.light' }}>
                <TableCell align="left" colSpan={12} sx={{ padding: 1 }}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="body2">本日再無存證</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        </>
    )
}

HiddenRow.propTypes = {
    dataArr: PropTypes.array.isRequired,
    renderMoreInfo: PropTypes.func.isRequired,
    renderClearanceOrder: PropTypes.func.isRequired,
}

export default HiddenRow
