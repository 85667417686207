import React from 'react'
import { AppBar, Toolbar, Box } from '@mui/material'
// import gpeLogo from '../../image/GPE LOGO.svg'
import logo from '../../image/ITM-Logo.png'

const Navbar = () => {
    return (
        <AppBar position="static" color="background" elevation={3}>
            <Toolbar>
                <Box display="flex" alignItems="center" gap={2}>
                    {/* <Box component="img" src={gpeLogo} width={110} height={36} /> */}
                    {/* <Typography variant="body1" sx={{ fontWeight: 600, fontFamily: 'myriad-variable, sans-serif' }}>
                        X
                    </Typography> */}
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box component="img" src={logo} width={120} height={56.06} />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
