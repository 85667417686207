const type = {
    NFT_OPERATION: 'NFT建立',
    ART_IMPORT: '畫作入庫',
    ART_CERTIFY: '增加認證註記',
    ART_IDENTIFICATION: '增加鑑識證據',
    ART_EXHIBIT: '增加參展註記',
    ART_COLLECT: '增加收藏註記',
    ART_TRANSACTION: '增加交易註記',
    ART_ADD_ADDITIONAL: '增加額外註記檔案',
}

export default type
