import { Backdrop, Box, Typography, styled, InputBase, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import ProgressBar from './StepperWithProgressBar/ProgressBar'
import { fetchResultWithId } from '../../../api/resultApi'

const CustomInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 10,
        backgroundColor: theme.palette.common.white + '80',
        border: '1px solid',
        borderColor: '#00000066',
        fontSize: 14,
        padding: '10px 20px',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            border: '2px solid',
            borderColor: theme.palette.primary.main,
        },
    },
}))

const Submission = ({ setData, setProgressDone, onApiFail }) => {
    const { id } = useParams()
    const [inputValue, setInputValue] = useState('')
    const [isProgressing, setIsProgressing] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onInputChange = (e) => {
        setInputValue(e.currentTarget.value?.toLocaleLowerCase())
    }

    const onBackropClose = () => {
        setTimeout(() => {
            setIsProgressing(false)
        }, 400)
    }

    const onSubmit = (id) => {
        setIsProgressing(true)
        setInputValue('')
        setData(null)

        fetchResultWithId(id)
            .then((res) => {
                setData(res.data)
            })
            .catch(() => {
                setIsProgressing(false)
                onApiFail()
            })
    }

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [inputValue])

    useEffect(() => {
        if (id && id !== '') {
            onSubmit(id)
        }
    }, [])

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ textAlign: 'center' }}>
                <Typography variant="serifTitle">藝術品數位權證登錄及管理平台</Typography>
                <Typography variant="h6" sx={{ mt: 1, fontWeight: 400, letterSpacing: '0.09em' }}>
                    輸入您的藝術品編號，查閱詳細資訊
                </Typography>
                <Box width="100%" textAlign="-webkit-center" mt={5}>
                    <Box sx={{ width: { lg: '35%', md: '45%', sm: '80%', xs: '100%' } }}>
                        <CustomInput fullWidth placeholder="輸入查詢編號" value={inputValue} onChange={onInputChange} />
                    </Box>
                </Box>
                <Button variant="contained" disabled={disabled} sx={{ mt: 5 }} onClick={() => onSubmit(inputValue)}>
                    <Typography>檢索</Typography>
                </Button>
                <Box mt={6} maxWidth={700}>
                    <Typography variant="body1">保證藝術品真實性與價值，透明安全的交易環境</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.7 }}>
                        這是一個獨特的藝術品權證交易平台，結合了畫廊協會認證、ITM的dNFT和區塊鏈存證功能。這個平台為藝術品市場帶來更高的透明度、安全性和真實性。通過ITM的dNFT技術，每件藝術品都有獨特的數位身份證明，保護其歷程和所有權。而區塊鏈則確保數位證書的安全存儲和防篡改性。此交易平台提供安全、透明的環境，由畫廊協會認證的藝術品真實性和價值可信可靠。透過區塊鏈技術，交易記錄永久存儲且無法被篡改，確保交易的透明度。這個平台為藝術品愛好者和收藏家提供了一個可信賴的場所，進行安全可靠的交易。
                    </Typography>
                </Box>
                <Box mt={5} maxWidth={700}>
                    <Typography variant="body1">ITM dNFT - 保障藝術品真實性與數位資產的獨特證明</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.7 }}>
                        ITM
                        dNFT結合了ITM（數位存證技術）和NFT（非同質化代幣）的概念，為藝術品提供可靠且安全的數位身份證明。每件藝術品都擁有唯一的數位身份，存儲於不可篡改的區塊鏈中，保護藝術品真實性和歷史。ITM
                        dNFT使藝術品擁有者能夠驗證真品並追溯其創作過程。它提升了藝術市場的透明度和可信度，消除了偽造風險。這項技術創新為藝術愛好者、收藏家和市場帶來更大的價值和信心，推動了藝術品市場的發展。
                    </Typography>
                </Box>
            </Box>
            <Backdrop
                sx={{
                    zIndex: 1,
                    color: 'primary',
                    backgroundColor: 'rgb(0, 0, 0, 0.8)',
                }}
                open={isProgressing}
                transitionDuration={{ exit: 500 }}
            >
                <Box mt={3} sx={{ maxWidth: '65%' }}>
                    <ProgressBar
                        onBackropClose={onBackropClose}
                        setProgressDone={setProgressDone}
                        isProgressing={isProgressing}
                    />
                </Box>
            </Backdrop>
        </Box>
    )
}

Submission.propTypes = {
    setData: PropTypes.func.isRequired,
    setProgressDone: PropTypes.func.isRequired,
    onApiFail: PropTypes.func.isRequired,
}

export default Submission
