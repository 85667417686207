import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Collapse, IconButton, Modal, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import BlockVerificationTable from './BlockVerificationTable'
import VerifyListTable from './VerifyListTable'
import MoreInfoModal from './VerifyListTableRow/MoreInfoModal'
import verifyProcessStatusConstant from '../../../../constant/verifyProcessStatusConstant'
import { getTxHashBaseUrl, isEmpty } from '../../../../util/stringUtil'
import NFTDetailTable from './NFTDetailTable'
import { downloadFileFromUrl } from '../../../../util/fileUtil'

const ProofVerificationDetailSection = ({ detailData, proofTokenInfo }) => {
    const [detailExpand, setDetailExpand] = useState(true)
    const [listExpand, setListExpand] = useState(true)

    const [moreInfo, setMoreInfo] = useState({})
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false)
    const { txHash } = proofTokenInfo

    const handleVerifyRawData = (data) => {
        console.log('handleVerifyRawData() start', { data })
        let tempData = data
        tempData['verifyProcessStatus'] = verifyProcessStatusConstant.pending
        setMoreInfo({ ...data, tempData })

        setTimeout(() => {
            let tempData = data
            let cmdObject
            try {
                cmdObject = JSON.parse(data.cmd)
            } catch (error) {
                console.log('parse cmd error', error)
            }
            tempData['txHashBaseUrl'] = getTxHashBaseUrl(detailData.mintEnv)
            tempData['rawDataVerifyResult'] = {}
            tempData['rawDataVerifyResult']['fileName'] = cmdObject.fileName
            tempData['rawDataVerifyResult']['fileHash'] = cmdObject.fileHash
            tempData['rawDataVerifyResult']['verifyResult'] = 'PASS'
            tempData['verifyProcessStatus'] = verifyProcessStatusConstant.complete

            setMoreInfo({ ...data, tempData })
            setMoreInfoModalOpen(true)
        }, 3000)
    }

    const handleDownloadRawData = (info) => {
        console.log('handleDownloadRawData() start', { info })
        const cmdObject = JSON.parse(info.cmd)
        if (cmdObject['type'] === 'ART_IDENTIFICATION' && !isEmpty(cmdObject['identificationFileUrl'])) {
            downloadFileFromUrl(cmdObject['identificationFileName'], cmdObject['identificationFileUrl'])
        } else if (cmdObject['type'] === 'ART_IMPORT' && !isEmpty(cmdObject['imageFileUrl'])) {
            downloadFileFromUrl(cmdObject['imageName'], cmdObject['imageFileUrl'])
        } else if (cmdObject['type'] === 'ART_ADD_ADDITIONAL' && !isEmpty(cmdObject['additionalFileUrl'])) {
            downloadFileFromUrl(cmdObject['additionalFileName'], cmdObject['additionalFileUrl'])
        } else {
            let content = info.cmd
            let link = document.createElement('a')
            let blob = new Blob([content])
            let downloadUrl = URL.createObjectURL(blob)
            console.log('downloadUrl: ', downloadUrl)
            link.href = downloadUrl
            var fileName = `attested-raw-data-${info.clearanceOrder}-${info.indexValue}.json`
            link.download = fileName
            link.target = '_blank'
            link.click()
        }
    }

    const handleMoreInfoModalOpen = (data) => {
        setMoreInfoModalOpen(true)
        const txHashBaseUrl = getTxHashBaseUrl(detailData.mintEnv)
        setMoreInfo({ ...data, txHashBaseUrl })
        return data
    }

    const handleMoreInfoModalClose = () => {
        setMoreInfoModalOpen(false)
    }

    return (
        <>
            {txHash && txHash !== '' && (
                <Stack spacing={1}>
                    <Typography variant="h6" color="textPrimary">
                        NFT資訊
                    </Typography>
                    <NFTDetailTable detailData={detailData} proofTokenInfo={proofTokenInfo} />
                </Stack>
            )}
            <Box display="flex" alignItems="center" mt={4} mb={2}>
                <Typography variant="h6" color="textPrimary">
                    區塊鏈驗證詳情
                </Typography>
                <IconButton size="small" onClick={() => setDetailExpand((prev) => !prev)}>
                    {detailExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={detailExpand} timeout="auto" unmountOnExit>
                <BlockVerificationTable detailData={detailData} />
            </Collapse>
            <Box mt={4} mb={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" color="textPrimary">
                            驗證列表
                        </Typography>
                        <IconButton size="small" onClick={() => setListExpand((prev) => !prev)}>
                            {listExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Collapse in={listExpand} timeout="auto" unmountOnExit>
                <VerifyListTable
                    detailData={detailData}
                    handleMoreInfoModalOpen={handleMoreInfoModalOpen}
                    handleVerifyRawData={handleVerifyRawData}
                    handleDownloadRawData={handleDownloadRawData}
                />
            </Collapse>
            <Modal open={moreInfoModalOpen} onClose={handleMoreInfoModalClose}>
                <Box>
                    <MoreInfoModal
                        close={handleMoreInfoModalClose}
                        moreInfo={moreInfo}
                        handleVerifyRawData={handleVerifyRawData}
                        handleDownloadRawData={handleDownloadRawData}
                    />
                </Box>
            </Modal>
        </>
    )
}

ProofVerificationDetailSection.propTypes = {
    detailData: PropTypes.object.isRequired,
    proofTokenInfo: PropTypes.object,
}

export default ProofVerificationDetailSection
