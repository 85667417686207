import React from 'react'
import PropTypes from 'prop-types'
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Button,
    Typography,
    styled,
    IconButton,
    Modal,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { renderTime } from '../../../util/stringUtil'

const Container = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    overflowY: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '85%',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        maxHeight: '75%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '60%',
        maxWidth: 900,
        maxHeight: '85%',
    },
}))

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
}))

const TxRecordModal = ({ open, modalInfo, onClose }) => {
    return (
        <Modal id="tx-record-modal" open={open} onClose={onClose}>
            <Container>
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" color="textPrimary" sx={{ fontWeight: 500 }}>
                        交易紀錄
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
                <Box mt={2}>
                    <CustomTableContainer sx={{ border: 1, borderColor: 'common.grey' }}>
                        <Table>
                            <TableBody>
                                <TableRow id="seller-row">
                                    <TableCell>
                                        <Typography variant="body1">賣家</Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            {modalInfo.seller}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow id="buyer-row">
                                    <TableCell>
                                        <Typography variant="body1">買家</Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            {modalInfo.buyer}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow id="price-row">
                                    <TableCell>
                                        <Typography variant="body1">金額</Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            {modalInfo.price}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow id="modalInfo-time-row">
                                    <TableCell>
                                        <Typography variant="body1">紀錄時間</Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            {renderTime(modalInfo.timestamp)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {modalInfo.otherTxInfo && (
                                    <TableRow id="buyer-row">
                                        <TableCell>
                                            <Typography variant="body1">其他交易資訊</Typography>
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                {modalInfo.otherTxInfo}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CustomTableContainer>
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button id="more-info-close-btn" variant="contained" onClick={onClose} size="small">
                        關閉
                    </Button>
                </Box>
            </Container>
        </Modal>
    )
}

TxRecordModal.propTypes = {
    open: PropTypes.bool.isRequired,
    modalInfo: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default TxRecordModal
