const snackbarSeverityConstant = {
    error: 'error',
    warning: 'warning',
    success: 'success',
}

export const snackbarContent = {
    unlockFail: {
        text: '無法解鎖內容，連接的錢包位址與作品擁有者的錢包位址不一致',
        severity: snackbarSeverityConstant.error,
    },
    unlockSuccess: {
        text: '解鎖成功！',
        severity: snackbarSeverityConstant.success,
    },
    userReject: {
        text: '您已拒絕連接至 MetaMask',
        severity: snackbarSeverityConstant.error,
    },
    metaMaskLock: {
        text: '您的 MetaMask 帳戶為鎖定狀態，請點選右上方的 MetaMask 插件，解鎖帳戶後再試一次',
        severity: snackbarSeverityConstant.error,
    },
    alreadyProcessing: {
        text: 'MetaMask 正在處理其他請求，請確認您的 MetaMask 插件視窗',
        severity: snackbarSeverityConstant.warning,
    },
    otherError: {
        text: '網站在處理您的需求時發生錯誤，請重新登入並稍後再試',
        severity: snackbarSeverityConstant.error,
    },
}
