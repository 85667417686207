import axios from '../axios/demoServerAxios'

const fetchNftTokenInfo = (tokenId) => {
    console.log('fetchNftTokenInfo() tokenId=', tokenId)

    return new Promise((myResolve, myReject) => {
        axios
            .get(`/rest/proof-token/art/${tokenId}`)
            .then((res) => {
                const data = {
                    proofFileName: res.data.proofTokenFileName,
                    downloadProofTokenUrl: res.data.downloadProofTokenUrl,
                    idCode: res.data.artID,
                    txHash: res.data.tokenTxHash,
                    tokenId: res.data.tokenID,
                }
                myResolve({ data })
            })
            .catch((error) => {
                console.log('fetchNftTokenInfo error:', error)
                myReject(error)
            })
    })
}

export { fetchNftTokenInfo }
