import verificationAxios from '../axios/verificationAxios'
import { getArtworkCertification, getAttesterAddress } from '../util/stringUtil'

const countResult = (list) => {
    let successCount = 0
    let totalCount = 0
    let modifiedCount = 0
    list.forEach((proof) => {
        if (proof.existenceType === 'EXIST') {
            if (proof.pass) {
                successCount += 1
            } else {
                modifiedCount += 1
            }
            totalCount += 1
        }
    })
    return { totalCount, successCount, modifiedCount }
}

const arrangeProofDetailList = (list) => {
    let arrangedCertificationProofDetailList = []
    for (let i = 0; i < list.length; i++) {
        const address = getAttesterAddress(list[i].cmd)
        const certification = getArtworkCertification(address)
        const toPushObj = { ...list[i], certification }
        arrangedCertificationProofDetailList.push(toPushObj)
    }
    return arrangedCertificationProofDetailList
}

const verifyProofFile = (file) => {
    console.log('verifyProofFile() request:', { file })
    const formData = new FormData()
    formData.append('verification-proof', file)
    return new Promise((myResolve, myReject) => {
        verificationAxios({
            method: 'post',
            url: '/verifyProof',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((res) => {
                const arrangedProofDetailList = arrangeProofDetailList(res.data.proofDetailList)
                const { totalCount, successCount, modifiedCount } = countResult(arrangedProofDetailList)
                myResolve({
                    data: {
                        ...res.data,
                        totalCount,
                        successCount,
                        modifiedCount,
                        proofDetailList: arrangedProofDetailList,
                    },
                })
            })
            .catch((error) => {
                console.log('verifyProofFile() error:', error)
                myReject(error)
            })
    })
}

export { verifyProofFile }
