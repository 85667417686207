import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography, CircularProgress, styled, IconButton } from '@mui/material'
import GavelIcon from '@mui/icons-material/Gavel'
import GetAppIcon from '@mui/icons-material/GetApp'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import proofExistenceType from '../../../../../constant/proofExistenceType'
import verifyProcesStatusConstant from '../../../../../constant/verifyProcessStatusConstant'
import MoreInfoDetailTable from './MoreInfoDetailTable'
import RawDataVerifyResult from './RawDataVerifyResult'

const Container = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    overflowY: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '85%',
    [theme.breakpoints.down('sm')]: {
        width: '80%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
        maxHeight: '75%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '60%',
        maxWidth: 900,
        maxHeight: '85%',
    },
}))

const MoreInfoModal = ({ moreInfo, close, handleVerifyRawData, handleDownloadRawData }) => {
    return (
        <Container id="more-info-modal">
            <Box mt={2} display="flex" justifyContent="space-between">
                <Typography variant="h5" color="textPrimary">
                    更多資訊
                </Typography>
                <IconButton onClick={close}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
            {moreInfo.existenceType === proofExistenceType.exist && (
                <Box mt={1} mb={3}>
                    <Box display="flex" alignItems="center" gap={2}>
                        {!moreInfo.rawDataVerifyResult && (
                            <Button
                                startIcon={
                                    moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ? (
                                        <CircularProgress size={18} sx={{ color: 'white' }} />
                                    ) : (
                                        <GavelIcon />
                                    )
                                }
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => handleVerifyRawData(moreInfo)}
                            >
                                驗證原始資料
                            </Button>
                        )}
                        <Button
                            startIcon={<GetAppIcon />}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleDownloadRawData(moreInfo)}
                        >
                            下載原始資料
                        </Button>
                    </Box>
                </Box>
            )}
            {(moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ||
                moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete) && (
                <Box mb={4}>
                    <Typography variant="h6" color="textPrimary">
                        原始資料驗證結果
                    </Typography>
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending && (
                        <Box py={1} display="flex" justifyContent="center">
                            <CircularProgress size={24} />
                        </Box>
                    )}
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete && (
                        <Box mt={1}>
                            <RawDataVerifyResult detailData={moreInfo} />
                        </Box>
                    )}
                </Box>
            )}
            <Typography variant="h6" color="textPrimary">
                詳細資訊
            </Typography>
            <Box mt={1}>
                <MoreInfoDetailTable detailData={moreInfo} />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button id="more-info-close-btn" variant="contained" onClick={close} size="small">
                    關閉
                </Button>
            </Box>
        </Container>
    )
}

MoreInfoModal.propTypes = {
    moreInfo: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
}

export default MoreInfoModal
