import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Divider, styled, Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { getTxHashBaseUrl } from '../../../../util/stringUtil'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}))

const NFTDetailTable = ({ detailData, proofTokenInfo }) => {
    const { txHash } = proofTokenInfo
    const onViewMintTxClick = () => {
        const txHashBaseUrl = getTxHashBaseUrl(detailData.evmEnv)
        const url = `${txHashBaseUrl}/${txHash}`
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
        link.click()

        console.log('env: ', detailData.mintEnv)
        console.log('link: ', link)
    }

    const bigIntId = BigInt('0x' + proofTokenInfo?.tokenId, 16)

    return (
        <Container border={1} sx={{ borderColor: 'common.grey' }}>
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">TokenID產生方式</Typography>
                <Typography variant="body2">{`ITM dNFT 利用對索引值（IndexValueKey）取雜湊來產生 Token ID`}</Typography>
                <Typography variant="body2">
                    {`此次Token ID為：Sha256(${detailData?.proofDetailList[0].indexValue?.slice(0, -3)}) `}
                </Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">Token ID</Typography>
                <Typography variant="body2">{proofTokenInfo?.tokenId}</Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">Token ID(10進制)</Typography>
                <Typography variant="body2">{bigIntId.toString()}</Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<OpenInNewIcon />}
                    onClick={onViewMintTxClick}
                    size="small"
                    sx={{ inlineSize: 'fit-content' }}
                >
                    查看鏈上鑄造資訊
                </Button>
            </Box>
        </Container>
    )
}

NFTDetailTable.propTypes = {
    detailData: PropTypes.object.isRequired,
    proofTokenInfo: PropTypes.object.isRequired,
}

export default NFTDetailTable
