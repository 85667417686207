const fetchFile = (url) => {
    return fetch(url, {
        method: 'GET',
    }).then((res) => {
        return res.blob()
    })
}

const downloadFileFromUrl = (fileName, fileUrl) => {
    fetchFile(fileUrl).then((blob) => {
        let url = URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
    })
}

export { fetchFile, downloadFileFromUrl }
