import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box, Typography, Divider, Card, CardMedia, CardContent, Button, Stack, Link } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import VerifiedIcon from '@mui/icons-material/Verified'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import itmLogo from '../../../image/ITM-Logo.png'
import artDecoLogo from '../../../image/art-deco-logo.png'
import { getContractAddressUrl, renderDate, renderTime } from '../../../util/stringUtil'
import { downloadFileFromUrl } from '../../../util/fileUtil'

const numberTitleWidth = 90
const numberTitleHeight = 90

const DetailRowBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
}))

export const DetailCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    width: 700,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white + '80',
}))

export const DetailCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
})

const NumberTitle = ({ children }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: numberTitleWidth,
                height: numberTitleHeight,
                borderRadius: '50%',
                border: '1px solid black',
            }}
        >
            {children}
        </Box>
    )
}

const MainContent = ({ data, isUnlocked, handleModalOpen, onUnlockClick }) => {
    const contractAddressUrl = getContractAddressUrl('GOERLI', data.contractAddress)

    const onDownloadProofFileClick = (fileName, url) => {
        downloadFileFromUrl(fileName, url)
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" m={2} mt={1} sx={{ position: 'relative' }}>
            <DetailRowBox>
                <NumberTitle>
                    <Typography variant="serifNumTitle">01</Typography>
                </NumberTitle>
                <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                <DetailCard variant="outlined">
                    <CardMedia component="img" sx={{ ml: 2, width: 120, height: 56.06 }} src={itmLogo} />
                    <DetailCardContent sx={{ ml: 3 }}>
                        <Typography variant="body1">區塊鏈智能合約：{data.smartContract}</Typography>
                        <Typography variant="body1">區塊鏈類別：{data.blockchainType}</Typography>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1">合約地址：</Typography>
                            <Link
                                variant="body1"
                                color="info.main"
                                underline="none"
                                href={contractAddressUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {data.contractAddress}
                            </Link>
                        </Box>
                        <Box>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID：
                            </Typography>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                {data.tokenID}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID(10進制)：
                            </Typography>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                {BigInt('0x' + data.tokenID, 16).toString()}
                            </Typography>
                        </Box>
                    </DetailCardContent>
                </DetailCard>
            </DetailRowBox>
            <DetailRowBox>
                <NumberTitle>
                    <Typography variant="serifNumTitle">02</Typography>
                </NumberTitle>
                <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                <DetailCard variant="outlined">
                    <CardMedia component="img" sx={{ ml: 1, width: 140 }} src={data.imageFileUrl} />
                    <DetailCardContent sx={{ ml: 3 }}>
                        <Typography variant="body1">入庫日期：{renderDate(data.importTimestamp)}</Typography>
                        <Typography variant="body1">作者：{data.author}</Typography>
                        <Typography variant="body1">品名：{data.title}</Typography>
                        <Typography variant="body1">編號：{data.artID}</Typography>
                        <Box display="flex" alignItems="center" gap={0.5} mt={1}>
                            <Typography variant="body1" color="info.main">
                                本作品通過『畫廊協會鑑定委員會』認證
                            </Typography>
                            <TaskAltIcon color="success" fontSize="small" />
                        </Box>
                    </DetailCardContent>
                </DetailCard>
            </DetailRowBox>
            {data['organizations'].map((org, index) => (
                <React.Fragment key={`organization-${index}`}>
                    <DetailRowBox>
                        <NumberTitle>
                            <Typography variant="serifNumTitle">0{index + 3}</Typography>
                        </NumberTitle>
                        <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                        <DetailCard variant="outlined">
                            <Box backgroundColor="#676767" ml={2} p={1}>
                                <CardMedia component="img" sx={{ width: 110, height: 55 }} src={artDecoLogo} />
                            </Box>
                            <DetailCardContent sx={{ ml: 4 }}>
                                <Box display="flex" alignItems="center" gap={0.5}>
                                    <Typography variant="body1">鑑識單位名稱：{org.name}</Typography>
                                    <VerifiedIcon color="success" fontSize="small" />
                                </Box>
                                <Typography variant="body1">鑑識日期：{renderDate(org.identifyTimestamp)}</Typography>
                                <Typography variant="body1">鑑識檔案名稱：{org.identificationFileName}</Typography>
                                {isUnlocked ? (
                                    <>
                                        <Box display="flex">
                                            <Typography variant="body1">鑑識檔案下載：</Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ color: 'info.main', '&:hover': { cursor: 'pointer' } }}
                                                onClick={() =>
                                                    onDownloadProofFileClick(
                                                        org.identificationFileName,
                                                        org.identificationDownloadUrl,
                                                    )
                                                }
                                            >
                                                點此下載
                                            </Typography>
                                        </Box>
                                        <Box display="flex">
                                            <Typography variant="body1">證據檔案下載：</Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ color: 'info.main', '&:hover': { cursor: 'pointer' } }}
                                                onClick={() =>
                                                    onDownloadProofFileClick(org.proofFileName, org.proofDownloadUrl)
                                                }
                                            >
                                                點此下載
                                            </Typography>
                                        </Box>
                                        <Box display="flex">
                                            <Typography variant="body1">採樣確認書檔案下載：</Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ color: 'info.main', '&:hover': { cursor: 'pointer' } }}
                                                onClick={() =>
                                                    onDownloadProofFileClick(
                                                        org.samplingFileName,
                                                        org.samplingFileDownloadUrl,
                                                    )
                                                }
                                            >
                                                點此下載
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body1">證據檔案下載：身分認證後解鎖</Typography>
                                        <Typography variant="body1">鑑識檔案下載：身分認證後解鎖</Typography>
                                        <Typography variant="body1">採樣確認書檔案下載：身分認證後解鎖</Typography>
                                    </>
                                )}
                            </DetailCardContent>
                        </DetailCard>
                    </DetailRowBox>
                </React.Fragment>
            ))}
            {isUnlocked ? (
                <DetailRowBox>
                    <NumberTitle>
                        <Typography variant="serifNumTitle">{`0${3 + data['organizations'].length}`}</Typography>
                    </NumberTitle>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        <DetailCardContent sx={{ flexDirection: 'row', gap: 6, ml: 1 }}>
                            <Stack spacing={3}>
                                <Box display="flex" gap={0.5}>
                                    <Typography variant="body1">展覽歷程：</Typography>
                                    <Stack spacing={0.5}>
                                        {data['exhibition'].map((item, index) => (
                                            <Typography key={`exhibition-${index}`} variant="body1">
                                                {index + 1}. {item}
                                            </Typography>
                                        ))}
                                    </Stack>
                                </Box>
                                <Box display="flex" gap={0.5}>
                                    <Typography variant="body1">收藏紀錄：</Typography>
                                    <Stack spacing={0.5}>
                                        {data['collection'].map((item, index) => (
                                            <Typography key={`collection-${index}`} variant="body1">
                                                {index + 1}. {item}
                                            </Typography>
                                        ))}
                                    </Stack>
                                </Box>
                            </Stack>
                            <Box display="flex" gap={0.5}>
                                <Typography variant="body1">交易紀錄：</Typography>
                                <Stack spacing={0.5}>
                                    {data['record'].map((record, index) => (
                                        <Link
                                            key={`record-${index}`}
                                            component="button"
                                            onClick={() => handleModalOpen(record)}
                                        >
                                            {renderTime(record.timestamp)}
                                        </Link>
                                    ))}
                                </Stack>
                            </Box>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
            ) : (
                <DetailRowBox>
                    <NumberTitle>
                        <LockOutlinedIcon sx={{ fontSize: '3.1rem' }} />
                    </NumberTitle>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined" sx={{ justifyContent: 'center' }}>
                        <DetailCardContent>
                            <Typography variant="h5" sx={{ wordBreak: 'break-all', fontWeight: 500 }}>
                                詳細資料將於身分認證後解鎖
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                sx={{ mt: 2, borderRadius: 50 }}
                                onClick={onUnlockClick}
                            >
                                連結 Metamask 以認證
                            </Button>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
            )}
        </Box>
    )
}

MainContent.propTypes = {
    data: PropTypes.object.isRequired,
    isUnlocked: PropTypes.bool.isRequired,
    handleModalOpen: PropTypes.func.isRequired,
    onUnlockClick: PropTypes.func.isRequired,
}

NumberTitle.propTypes = {
    children: PropTypes.element.isRequired,
}

export default MainContent
