import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Divider, styled, Tooltip } from '@mui/material'
import {
    getAttestType,
    getAttesterAddress,
    getAttesterCertificationInfo,
    isEmpty,
    renderCmd,
    renderExistence,
    renderTime,
} from '../../../../../util/stringUtil'
import proofExistenceType from '../../../../../constant/proofExistenceType'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorCircleIcon from '@mui/icons-material/Error'
import VerifyStatus from './VerifyStatus'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}))

const MoreInfoDetailTable = ({ detailData }) => {
    const {
        cmd,
        certification,
        existenceType,
        txHash,
        txHashBaseUrl,
        clearanceOrder,
        indexValue,
        proofExistStatus,
        receiptTimestamp,
        merkleProofRootHash,
    } = detailData
    const attestType = getAttestType(cmd)
    const attesterAddress = getAttesterAddress(cmd)
    const attesterInfo = getAttesterCertificationInfo(certification)

    return (
        <Container border={1} sx={{ borderColor: 'common.grey' }}>
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">類別</Typography>
                <Typography variant="body2">{attestType && attestType !== '' ? attestType : '資料存證'}</Typography>
            </Box>
            {existenceType === proofExistenceType.exist && (
                <>
                    {!isEmpty(attesterAddress) && (
                        <>
                            <Divider sx={{ borderColor: 'common.grey' }} />
                            <Box m={2} display="flex" flexDirection="column" gap={1}>
                                <Typography variant="body1">存證者錢包地址</Typography>
                                <Typography variant="body2">{attesterAddress}</Typography>
                            </Box>
                        </>
                    )}

                    <>
                        <Divider sx={{ borderColor: 'common.grey' }} />
                        <Box m={2} display="flex" flexDirection="column" gap={1}>
                            <Typography variant="body1">存證者實名認證資訊</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2">{attesterInfo}</Typography>
                                {isEmpty(certification?.address) ? (
                                    <ErrorCircleIcon fontSize="small" sx={{ color: 'common.grey' }} />
                                ) : (
                                    <CheckCircleIcon fontSize="small" sx={{ color: 'info.main' }} />
                                )}
                            </Box>
                        </Box>
                    </>
                </>
            )}
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">區塊鏈詳情</Typography>
                {txHash !== null ? (
                    <Tooltip title="點擊數字前往區塊鏈瀏覽器確認鏈上紀錄" placement="right">
                        <Typography
                            id="more-info-co-link"
                            component="a"
                            variant="body2"
                            href={`${txHashBaseUrl}/${txHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: 'info.main', textDecoration: 'none', width: 'fit-content' }}
                        >
                            {clearanceOrder}
                        </Typography>
                    </Tooltip>
                ) : (
                    <>{clearanceOrder}</>
                )}
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">Index Value</Typography>
                <Typography variant="body2">{indexValue}</Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">存在性</Typography>
                <Typography variant="body2">{renderExistence(existenceType)}</Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">驗證狀態</Typography>
                <VerifyStatus status={proofExistStatus} iconOnly={false} />
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">存在性</Typography>
                <Typography variant="body2">{renderExistence(existenceType)}</Typography>
            </Box>
            {existenceType === proofExistenceType.exist && (
                <>
                    <Divider sx={{ borderColor: 'common.grey' }} />
                    <Box m={2} display="flex" flexDirection="column" gap={1}>
                        <Typography variant="body1">存證時間</Typography>
                        <Typography variant="body2">{renderTime(receiptTimestamp)}</Typography>
                    </Box>
                    {!isEmpty(merkleProofRootHash) && (
                        <>
                            <Divider sx={{ borderColor: 'common.grey' }} />
                            <Box m={2} display="flex" flexDirection="column" gap={1}>
                                <Typography variant="body1">Root Hash</Typography>
                                <Typography variant="body2">{merkleProofRootHash}</Typography>
                            </Box>
                        </>
                    )}
                </>
            )}
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">存證資訊欄</Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {renderCmd(cmd)}
                </Typography>
            </Box>
        </Container>
    )
}

MoreInfoDetailTable.propTypes = {
    detailData: PropTypes.object.isRequired,
}

export default MoreInfoDetailTable
