import axios from '../axios/demoServerAxios'

const moreInfo = {
    smartContract: 'ITM ART ERC-1155',
    blockchainType: '乙太坊',
}

const arrangeOrganizationList = (list, additionalList) => {
    let organizationList = []
    list.forEach(
        ({
            identificationOrg,
            identifyTimestamp,
            verificationFileName,
            verificationFileUrl,
            identificationFileName,
            identificationFileUrl,
        }) => {
            organizationList.push({
                name: identificationOrg,
                identifyTimestamp: identifyTimestamp,
                proofFileName: verificationFileName,
                proofDownloadUrl: verificationFileUrl,
                identificationFileName: identificationFileName,
                identificationDownloadUrl: identificationFileUrl,
                // FIXME fix this after production
                samplingFileName: additionalList[0]?.additionalFileName,
                samplingFileDownloadUrl: additionalList[0]?.additionalFileUrl,
            })
        },
    )
    return organizationList
}

const arrangeExibitionList = (list) => {
    let exibitionList = []
    list.forEach(({ exhibitPlace }) => {
        exibitionList.push(exhibitPlace)
    })
    return exibitionList
}

const arrangeCollectionList = (list) => {
    let collectionList = []
    list.forEach(({ collectorName }) => {
        collectionList.push(collectorName)
    })
    return collectionList
}

const arrangeTradeRecordList = (list) => {
    let tradeList = []
    list.forEach(({ sellerID, buyerID, price, currency, transactionTimestamp }) => {
        tradeList.push({
            seller: sellerID,
            buyer: buyerID,
            price: `${price} ${currency}`,
            timestamp: transactionTimestamp,
        })
    })
    return tradeList
}

const fetchResultWithId = (id) => {
    return new Promise((myResolve, myReject) => {
        axios
            .get(`/rest/art/nft/art-info/${id?.toLocaleLowerCase()}`)
            .then((res) => {
                console.log('res: ', res)
                const data = {
                    smartContract: moreInfo.smartContract,
                    blockchainType: moreInfo.blockchainType,
                    tokenID: res.data.tokenID,
                    contractAddress: res.data.contractAddress,
                    author: res.data.author,
                    title: res.data.title,
                    artID: res.data.artID,
                    imageFileUrl: res.data.imageFileUrl,
                    organizations: arrangeOrganizationList(
                        res.data.artIdentificationEntitySet,
                        res.data.additionalHistorySet,
                    ),
                    exhibition: arrangeExibitionList(res.data.exhibitHistorySet),
                    collection: arrangeCollectionList(res.data.collectionHistorySet),
                    record: arrangeTradeRecordList(res.data.tradingHistorySet),
                    importTimestamp: res.data.importTimestamp,
                    ownerAddress: res.data.ownerAddress,
                }

                myResolve({ data: data })
            })
            .catch((error) => {
                console.log('error: ', error)
                myReject(error)
            })
    })
}

const getCertificateFile = () => {
    let url = '/file/digital-certificate.pdf'
    let link = document.createElement('a')
    link.href = url
    link.download = 'Certificate of Authenticity.pdf'
    link.href = url
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()
}

export { fetchResultWithId, getCertificateFile }
