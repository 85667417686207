import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Alert, Snackbar } from '@mui/material'
import TxRecordModal from './TxRecordModal'
import { snackbarContent } from '../../../constant/snackbarContent'
import MainContent from './MainContent'
import InstallMetamaskSnackbar from '../../common/InstallMetamaskSnackbar'
import { getCertificateFile } from '../../../api/resultApi'

const VerifyResult = ({ data }) => {
    const baseUrl = window.location.origin
    const [modalOpen, setModalOpen] = useState(false)
    const [isUnlocked, setIsUnLocked] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarText, setSnackbarText] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('error')
    const [metamaskSnackbarOpen, setMetamaskSnackbarOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState(null)

    const handleSnackbarOpen = (text, severity) => {
        setSnackbarOpen(true)
        setSnackbarText(text)
        setSnackbarSeverity(severity)
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    const handleMetamaskSnackbarOpen = () => {
        setMetamaskSnackbarOpen(true)
    }

    const handleMetamaskSnackbarClose = () => {
        setMetamaskSnackbarOpen(false)
    }

    const handleModalOpen = (info) => {
        setModalOpen(true)
        setModalInfo(info)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const onAddressCheck = (accountAddress) => {
        console.log('onAddressCheck()', { accountAddress, ownerAddress: data.ownerAddress })
        const isMatch = accountAddress === data.ownerAddress
        if (isMatch) {
            setIsUnLocked(true)
            handleSnackbarOpen(snackbarContent.unlockSuccess.text, snackbarContent.unlockSuccess.severity)
        } else {
            setIsUnLocked(false)
            handleSnackbarOpen(snackbarContent.unlockFail.text, snackbarContent.unlockFail.severity)
        }
    }

    const isMetamaskInstalled = () => {
        if (window.ethereum === undefined) {
            handleMetamaskSnackbarOpen()
            return false
        } else {
            return true
        }
    }

    const connectAccount = () => {
        window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                onAddressCheck(accounts[0])
            })
            .catch((error) => {
                console.log('error: ', error)
                if (error.code === 4001) {
                    handleSnackbarOpen(snackbarContent.userReject.text, snackbarContent.userReject.severity)
                } else if (error.code === -32602) {
                    handleSnackbarOpen(snackbarContent.metaMaskLock.text, snackbarContent.metaMaskLock.severity)
                } else if (error.code === -32002) {
                    handleSnackbarOpen(
                        snackbarContent.alreadyProcessing.text,
                        snackbarContent.alreadyProcessing.severity,
                    )
                } else {
                    handleSnackbarOpen(snackbarContent.otherError.text, snackbarContent.otherError.severity)
                }
            })
    }

    const onUnlockClick = () => {
        if (isMetamaskInstalled()) {
            connectAccount()
        }
    }

    // const isMetamaskConnected = async () => {
    //     const accounts = await window.ethereum.request({ method: 'eth_accounts' })
    //     if (accounts.length) {
    //         console.log(`connected to ${accounts[0]}`)
    //     } else {
    //         console.log('not connected')
    //     }
    // }

    // useEffect(() => {
    //     if (isMetamaskInstalled()) {
    //         isMetamaskConnected()
    //     }
    // }, [])
    const onDownloadDigitalCertificateFileClick = () => {
        getCertificateFile()
    }

    return (
        <Box gap={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
                畫作歷程
            </Typography>
            <MainContent
                data={data}
                isUnlocked={isUnlocked}
                handleModalOpen={handleModalOpen}
                onUnlockClick={onUnlockClick}
            />
            {isUnlocked && (
                <Box my={5} display="flex" flexDirection="column">
                    <Button
                        variant="contained"
                        color="primary"
                        // href="/src/file/test-file.pdf"
                        onClick={onDownloadDigitalCertificateFileClick}
                    >
                        下載數位證書檔案
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`${baseUrl}/nft-history/${data.tokenID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mt: 2 }}
                    >
                        查看區塊鏈詳情
                    </Button>
                </Box>
            )}
            {modalInfo && <TxRecordModal open={modalOpen} modalInfo={modalInfo} onClose={handleModalClose} />}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarText}
                </Alert>
            </Snackbar>
            <InstallMetamaskSnackbar open={metamaskSnackbarOpen} onClose={handleMetamaskSnackbarClose} />
        </Box>
    )
}

VerifyResult.propTypes = {
    data: PropTypes.object.isRequired,
}

export default VerifyResult
