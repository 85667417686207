import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Stack } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { getCertificateFile } from '../../../api/resultApi'

const DetectedSection = ({ isVerifyDone, proofTokenInfo, isDetectFail }) => {
    const onDownloadProofFileClick = () => {
        if (proofTokenInfo?.downloadFileLink) {
            proofTokenInfo.downloadFileLink.click()
        }
    }
    const onDownloadDigitalCertificateFileClick = () => {
        getCertificateFile()
    }

    return (
        <Box mt={3} mb={4}>
            {isDetectFail ? (
                <Typography variant="body1">查無資料，請確認查詢資訊是否正確</Typography>
            ) : (
                proofTokenInfo.idCode && (
                    <Stack spacing={2}>
                        {isVerifyDone && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <CheckCircleIcon fontSize="small" color="success" />
                                <Typography color="textPrimary" variant="h6">
                                    驗證完成！
                                </Typography>
                            </Box>
                        )}
                        <Box
                            p={2}
                            sx={{ border: 1, borderColor: '#273272', borderRadius: 1, backgroundColor: '#FFFFFF' }}
                        >
                            <Box display="flex" alignItems="center">
                                <TaskAltIcon color="success" fontSize="small" />
                                <Typography
                                    variant="body1"
                                    sx={{ ml: 1 }}
                                >{`已檢驗藝術品編號：${proofTokenInfo.idCode}`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Button
                                startIcon={<CloudDownloadIcon />}
                                variant="contained"
                                color="primary"
                                onClick={onDownloadProofFileClick}
                                size="small"
                            >
                                下載證據檔案
                            </Button>
                            <Button
                                startIcon={<CloudDownloadIcon />}
                                variant="contained"
                                color="primary"
                                onClick={onDownloadDigitalCertificateFileClick}
                                size="small"
                            >
                                下載數位證書檔案
                            </Button>
                        </Box>
                    </Stack>
                )
            )}
        </Box>
    )
}

DetectedSection.propTypes = {
    isVerifyDone: PropTypes.bool,
    proofTokenInfo: PropTypes.object.isRequired,
    isDetectFail: PropTypes.bool,
}

export default DetectedSection
