import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Divider, styled } from '@mui/material'
import { renderCmd } from '../../../../../util/stringUtil'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}))

const RawDataVerifyResult = ({ detailData }) => {
    const { cmd, rawDataVerifyResult } = detailData

    return (
        <Container border={1} sx={{ borderColor: 'common.grey' }}>
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">原始資料</Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {renderCmd(cmd)}
                </Typography>
            </Box>
            <Divider sx={{ borderColor: 'common.grey' }} />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">原始資料驗證狀態</Typography>
                {rawDataVerifyResult.verifyResult === 'PASS' && (
                    <Box display="flex" alignItems="center">
                        <CheckIcon color="success" />
                        <Typography variant="body2">驗證成功</Typography>
                    </Box>
                )}
                {rawDataVerifyResult.verifyResult === 'FAILED' && (
                    <Box display="flex" alignItems="center">
                        <CloseIcon color="error" />
                        <Typography variant="body2">驗證失敗</Typography>
                    </Box>
                )}
            </Box>
        </Container>
    )
}

RawDataVerifyResult.propTypes = {
    detailData: PropTypes.object.isRequired,
}

export default RawDataVerifyResult
